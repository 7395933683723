import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import linkedInIcon from "../images/linkedin_icon.png"
import profileImage from "../images/jwvansickle.jpg"
import addToAppleWallet from "../images/Add_to_Apple_Wallet_rgb_US-UK.svg"

export default function Contact() {
  return (
    <Layout>
      <SEO
        title="Contact Me"
        description="Contact information for John VanSickle"
      />

      <div className="row">
        <div className="col-4">
          <img
            src={profileImage}
            alt="John VanSickle"
            style={{ width: "100%", borderRadius: "50%" }}
          />
        </div>

        <div className="col-8" style={{ paddingLeft: "30px" }}>
          <div className="row">
            <h5 className="">Contact Me</h5>
          </div>

          <div className="row">
            <div className="">
              Email:&nbsp;
              <a href="mailto:jwvansickle@icloud.com">jwvansickle@icloud.com</a>
            </div>
          </div>

          <div className="row mt-2">
            <div className="">
              <a
                href="https://www.linkedin.com/in/jwvansickle"
                target="_jwvansickle_linkedin"
                className="mr-2"
              >
                <img src={linkedInIcon} style={{ width: "50px" }} />
              </a>
            </div>

            <div className="">
              <a
                datatype="application/vnd.apple.pkpass"
                href={"/jwvansickle.pkpass"}
                target="_self"
                className="mr-2"
              >
                <img src={addToAppleWallet} style={{ height: "50px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
